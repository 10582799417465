import * as React from 'react';
import { combineClasses } from 'src/utils';
import { SEO } from './SEO';
import { Header } from './Header';
import { Footer } from './Footer';
import css from './PageLayout.module.scss';

interface IPageLayoutProps {
  /**
   * The title of the page that gets combined with the site titled to set the document.title property.
   */
  pageTitle?: string;
  featuredImage?: LS.GatsbyImageOrUrl;
  twitter?: string;
}

export const PageLayout: React.FC<IPageLayoutProps> = ({ children, pageTitle, featuredImage, twitter }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const mainClass = combineClasses([css.main, scrollPosition > 0 && css.scrolled]);

  React.useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className={css.layout}>
      <SEO pageTitle={pageTitle} featuredImage={featuredImage} twitter={twitter} />
      {/* <Header scrollPosition={scrollPosition} /> */}
      <main className={mainClass}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};
