import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Favicon from 'src/assets/favicon.ico';
import LinkScoreOverview from 'src/assets/ls-overview.png';

interface ISEOProps {
  pageTitle?: string;
  featuredImage?: LS.GatsbyImageOrUrl;
  twitter?: string;
}

export const SEO: React.FC<ISEOProps> = ({ pageTitle, featuredImage, twitter }) => {
  const query: LS.ISiteData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const [pageUrl, setPageUrl] = React.useState('');
  React.useEffect(() => {
    setPageUrl(window.location.href);
  }, []);

  const { description, title } = query.site.siteMetadata;
  const documentTitle = pageTitle ? `${pageTitle} - ${title}` : title;

  let socialImage = featuredImage || '';
  if (socialImage) {
    socialImage = typeof socialImage === 'string' ? socialImage : socialImage.publicURL || '';
  } else {
    socialImage = LinkScoreOverview || '';
  }

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{documentTitle}</title>
      <link rel='shortcut icon' href={Favicon} type='image/x-icon' />
      <link rel='canonical' href={pageUrl} />
      <meta name='description' content={description} />
      <meta name='twitter:card' content='summary_large_image' />
      {twitter && <meta name='twitter:site' content={`@${twitter}`} />}
      <meta name='twitter:title' content={documentTitle} />
      <meta name='twitter:description' content={description} />
      {socialImage && <meta name='twitter:image' content={socialImage} />}
      <meta property='og:site_name' content={title} />
      <meta property='og:title' content={documentTitle} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={pageUrl} />
      {socialImage && <meta property='og:image' content={socialImage} />}
    </Helmet>
  );
};
