/**
 * Utility function to combine multiple css classes into a single string for the className property of JSX elements.
 * The main benefit is that it checks if a class name exists and that it is not an empty string.
 *
 * Note that order matters. Last class passed ot the array may overwrite styles from the the first classes.
 *
 * @param cssClasses An array of css class names.
 */
export function combineClasses(cssClasses: LS.CSSClasses[]): string {
  let className = '';

  for (let index = 0; index < cssClasses.length; index += 1) {
    const cssClass = cssClasses[index];
    if (typeof cssClass === 'string' && cssClass.length) {
      className += `${cssClass} `;
    }
  }

  return className.trim();
}

export function dateToString(date: Date): string {
  const month = date.getMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;

  return `${date.getDate()}/${monthString}/${date.getFullYear()}`;
}

const EMAIL_REGEX = new RegExp('^(?!.*\\.\\.).+@.+\\.[^.]+$', 'i');
export function isValidEmail(email: string): boolean {
  const match = email.match(EMAIL_REGEX);
  return (match && match.length > 0) || false;
}
